import React, { useState, useContext, useEffect } from 'react';
import Select, { components } from 'react-select';

import HubbleOrderContext from '../../../context/hubbleOrderContext';
import { groupedProducts } from './groupedProductsList';
import StepWrapper from '../StepWrapper';
import './styles.scss';
import LeavingHubbleModal from '../../LeavingHubbleModal';

const GroupedBrand = ({ steps, setActiveSteps, isHydroSkyhy, isAffiliatePage, isV3}) => {
  const { brand, setBrand, setTaxes, setShippingAddress, brandSelected, setBrandSelected } = useContext(HubbleOrderContext);
  const products = groupedProducts
  const [productsData, setProductsData] = useState(products)
  const [product, setProduct] = useState(brand.title && brand.price ? brand : null);
  const [isError, setIsError] = useState(false);
  const [isOtherBrand, setIsOtherBrand] = useState(false)
  const [scrollNext, setScrollNext] =useState(false)
  const [outboundLink , setOutboundLink] = useState("http://contactscart.com")
  const [show, setShow] = useState(false)
  const [selectedGroup, setSelectedGroup] = useState(null);

  useEffect(() => {
    setProductsData(products)
  }, [isHydroSkyhy, isV3])

  const getIsCurrent = (optionValue) => {
    const brandValue = optionValue
    const current = brand?.value
    const isCurrent = current?.toLowerCase() === brandValue

    return isCurrent
  }

  useEffect(() => {

   if (brand.value !== "") {
    setProduct(brand)
   }

    if (!brand) {
      return
    }

    const selectedGroup =
      Object.entries(products)
        .find(([_, groupProducts]) =>
          groupProducts.some(_product => _product.value === brand?.value)
        )?.[0]

    const filteredProducts = products[selectedGroup]?.filter((product) => {
      const currentSelection = getIsCurrent(product?.value)
      return !currentSelection
    })

    const selectedProduct = products[selectedGroup]?.find((product) => {
      const currentSelection = getIsCurrent(product?.value)
      return currentSelection
    })

    const newProducts = {
      ...productsData,
      [selectedGroup]: selectedProduct ? [selectedProduct, ...filteredProducts] : filteredProducts
    }

    setProductsData(newProducts)
    setProduct(selectedProduct)
    setSelectedGroup(selectedGroup)
  }, [brand])

  const handleCCSelect = () => {
    setShow(!show)
    setProduct(null)
  }

  const handleProduct = (value) => {
    if (isAffiliatePage && value?.link) {
      setOutboundLink(value.link)
      setShow(true)
      setProduct(null)
      return
    }
    setIsOtherBrand(value.value !== 'hubble' ? true : false)
    setProduct(value);
    setBrand(value);
    setBrandSelected(true)
    setTaxes(0)
    setScrollNext(true)
    setShippingAddress({
      first_name: "",
      last_name: "",
      address1: "",
      address2: "",
      city: "",
      province: "",
      zipcode: "",
      phone: "",
    })
      window.dataLayer.push({ecommerce: null})
      window.dataLayer.push({
        event: "select_item",
        ecommerce: {
          items: [
            {
              item_id: value.cc_product_id || '2167100637257',
              index: 0,
              item_name: value.title,
              currency: "USD",
              discount: 0,
              item_brand: value.title.includes("Hubble") ? "Hubble" : value.title,
              item_category: "contacts",
              item_category2: 'spherical',
              item_category3: 'N/A', //gender
              item_category4: 'N/A',
              item_category5: 'N/A',
              item_list_id: "Normal",
              item_variant: 'N/A',
              price: parseFloat(value.sale_price)
            }
          ]
        }
      })
  };

  const handleSubmit = () => {
    setIsError(false);

    if (!product) return setIsError(true);

    setActiveSteps({ Rx: true });
    setTimeout(() => {
      const currentRef = steps.find(({ title }) => title === 'Rx')
      currentRef.ref.current.scrollIntoView({behavior: 'smooth'})
    }, 500);
  };

  useEffect(() => {
    product && brandSelected && handleSubmit()
  }, [product,scrollNext])

  let options = [
    {label: "Hubble Daily Contact Lenses", options: productsData.hubble, group: "hubble"},
    {label: "Contact Lenses for Astigmatism", options: productsData.astigmatism, group: "astigmatism"},
    {label: "Popular Contact Lenses", options: productsData.popular, group: "popular"},
  ].sort((a, b) => (b.group === selectedGroup) - (a.group === selectedGroup));

  return (
    <StepWrapper
      title='What’s Your Prescribed Brand?'
      steps={steps}
      currentStep='Brand'
      onSubmit={handleSubmit}
      className='brand'
      isV3={isV3}
    >
      <Select
        className={`hubble-select brand ${
          isError && !product ? 'error' : ''
        } ${isV3 ? 'v3-select' : ''}`.trim()}
        classNamePrefix='HubbleSelect'
        value={brandSelected && product}
        onChange={handleProduct}
        placeholder={isV3 ? 'Select Brand' : '--'}
        options={options}
        getIsCurrent={getIsCurrent}
        components={{ Option: isV3 ? OptionIntake : CustomOption, SingleValue: CustomSingleValue, GroupHeading: CustomGroupHeading }}
        filterOption={(option, input) =>
          !input ||
          option.data.title?.toLowerCase?.().includes(input.toLowerCase()) ||
          option.label?.toLowerCase?.().includes(input.toLowerCase())
        }
      />
      {!isAffiliatePage && !isV3 && isOtherBrand && (
        <p>
          Can’t find your prescribed brand? Check out our sister site, <a href="https://contactscart.com" target='_blank'>ContactsCart</a>, for additional products.
        </p>
      )}
      <LeavingHubbleModal show={show} setShow={() => handleCCSelect()} link={outboundLink} setCurrentItem={setProduct} />
    </StepWrapper>
  );
};

const CustomGroupHeading = ({data: {label}, selectProps: {classNamePrefix}, ...props}) => {
  return (
    <div className={`${classNamePrefix}__group-heading`}>
      {label}
    </div>
  )
}

const CustomOption = (props, {isHydroSkyhy}) => {
  const { title, price, sale_price, value } = props.data;
  const isContactsCartLink = value === 'contactscart'

  return (
    <components.Option {...props}>
      <div className={`title ${isContactsCartLink ? "link-option" : ""}`}>{title}</div>
      {(price || sale_price) && <div className='price-container'>
        {/* {sale_price && price && <span className='crossed-price'>${price}</span>} */}

        {!isHydroSkyhy && <span className='price'>${sale_price || price}</span>}
        {isHydroSkyhy && <span className='price'>${sale_price}</span>}
      </div>}
    </components.Option>
  );
};

const OptionIntake = (props) => {
  const { title, sale_price, value } = props.data
  const getIsCurrent = props.selectProps?.getIsCurrent
  const isCurrent = getIsCurrent(value)

  return (
    <components.Option {...props}>
      <div className="text h8 neutral ocean-blue price-intake">
        {title} <span>
          {sale_price &&
        <b>
           - ${sale_price}
        </b>}
        </span>

      <br/>
      <span className="text h9 neutral">{isCurrent && "(currently selected)"}</span>
       </div>
    </components.Option>
  )
}

const CustomSingleValue = (props, {isHydroSkyhy}) => {
  const { title, price, sale_price, value } = props.data;
  const isContactsCartLink = value === "contactscart"

  return (
    <components.SingleValue {...props}>
      <div className={`title ${isContactsCartLink ? "link-option" : ""}`}>{title}</div>
      {(price || sale_price) && (
        <div className="price-container">
          {/* {sale_price && price && <span className='crossed-price'>${price}</span>} */}

          {!isHydroSkyhy && (
            <span className="price">${sale_price || price}</span>
          )}
          {isHydroSkyhy && <span className="price">${sale_price}</span>}
        </div>
      )}
    </components.SingleValue>
  )
};

export default GroupedBrand;
